import _debounce from 'lodash.debounce';

import { Watcher } from '@cvfm-front/commons-utils';
import {
  Lang,
  OrderEsPrivateDTO,
  OrderEsSearchQueryDTO,
  OrderEsSearchQueryPagedDTO,
  OrderEsSearchResultDTO,
  OrderSortParameters,
  SortOrder,
  TeamDTO,
  TeamSubscriptionConfiguration,
} from '@cvfm-front/tefps-types';
import { InternalAgent } from 'api/auth/types';
import { searchEsOrderPaged } from 'api/cvfm-core-subscription/order';
import { fetchTeamSubscriptionConfigurationByOperationTypeByAgent } from 'api/cvfm-core-directory/team';
import { DEFAULT_FILTER_DEBOUNCE } from 'commons/const';

function OrderFilterService() {
  const { getValue: getFilters, setValue, watchValue: watchFilters } = Watcher<
    OrderEsSearchQueryDTO
  >({});
  const {
    getValue: getResults,
    setValue: setResults,
    watchValue: watchResults,
  } = Watcher<OrderEsSearchResultDTO | undefined>(undefined);
  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<OrderSortParameters>({
    page: 0,
    index: 0,
    sortOrder: SortOrder.DESC,
    field: 'endOfValidity',
  });
  const {
    getValue: getOrders,
    setValue: setOrders,
    watchValue: watchOrders,
  } = Watcher<OrderEsPrivateDTO[]>([]);

  const {
    getValue: getAgentTeamSubscriptionConfiguration,
    setValue: setAgentTeamSubscriptionConfiguration,
  } = Watcher<TeamSubscriptionConfiguration | undefined>(undefined);

  const { getValue: isAgentInTeam, setValue: setIsAgentInTeam } = Watcher<
    boolean
  >(false);

  const searchPageMaxRecords = 25;

  async function initAgentTeamSubscriptionConfiguration(
    userInfo: InternalAgent | null | undefined
  ): Promise<void> {
    if (userInfo && userInfo.worksFor) {
      const agentTeamSubscriptionConfiguration = await fetchTeamSubscriptionConfigurationByOperationTypeByAgent(
        userInfo.agentId,
        'SUBSCRIPTION'
      );
      // if not team, we get an empty object
      if (Object.keys(agentTeamSubscriptionConfiguration).length !== 0) {
        setAgentTeamSubscriptionConfiguration(
          agentTeamSubscriptionConfiguration
        );
        setIsAgentInTeam(true);
      } else {
        setIsAgentInTeam(false);
      }
    }
  }

  async function loadOrders(
    maxRecords?: number,
    pageParam?: number
  ): Promise<OrderEsSearchResultDTO> {
    // Build current search from state

    const splitFilters = { ...getFilters() };
    splitFilters.plates =
      splitFilters.plates && splitFilters.plates[0].split(',');
    splitFilters.subscriberIds =
      splitFilters.subscriberIds && splitFilters.subscriberIds[0].split(',');
    splitFilters.orderIds =
      splitFilters.orderIds && splitFilters.orderIds[0].split(',');

    const search = {
      page: typeof pageParam === 'number' ? pageParam : getSortParameter().page,
      maxRecords: maxRecords || searchPageMaxRecords,
      query: splitFilters,
      sort: {
        sortOrder: getSortParameter().sortOrder,
        sortField: getSortParameter()?.field,
      },
    } as OrderEsSearchQueryPagedDTO;
    const result = await searchEsOrderPaged(search);
    setResults(result);
    if (getSortParameter().page === 0) {
      setOrders(result.results);
    } else {
      setOrders([...getOrders(), ...result.results]);
    }
    return result;
  }

  // Debounce fonction pour les changements de filtres
  const debounceFetchOrders = _debounce(async () => {
    await loadOrders();
  }, DEFAULT_FILTER_DEBOUNCE);

  function setFilters(
    callback: (filters: OrderEsSearchQueryDTO) => OrderEsSearchQueryDTO
  ) {
    const newFilters = callback(getFilters());
    setValue(newFilters);
    setSortParameter({ ...getSortParameter(), page: 0 });
    void debounceFetchOrders();
  }

  function updateSortParameters(
    callback: (sortParameters: OrderSortParameters) => OrderSortParameters
  ) {
    const newSortParameters = callback(getSortParameter());
    setSortParameter({ ...newSortParameters, page: 0 });
    void debounceFetchOrders();
  }

  function moreOrders(page: number) {
    setSortParameter({ ...getSortParameter(), page });
    void loadOrders();
  }

  function getAgentTeamFilters(): OrderEsSearchQueryDTO {
    const agentTeamSubscriptionConfiguration = getAgentTeamSubscriptionConfiguration();

    const resultFilters: OrderEsSearchQueryDTO = {};

    if (agentTeamSubscriptionConfiguration) {
      const productIds: string[] = agentTeamSubscriptionConfiguration
        ? agentTeamSubscriptionConfiguration.authorizedBundles.concat(
            agentTeamSubscriptionConfiguration.authorizedEligibilities
          )
        : [];
      if (productIds.length > 0) {
        resultFilters.productIds = productIds;
      }

      if (agentTeamSubscriptionConfiguration.authorizedLabels.length > 0) {
        resultFilters.tags =
          agentTeamSubscriptionConfiguration?.authorizedLabels;
      }

      if (agentTeamSubscriptionConfiguration.authorizedLangs.length > 0) {
        resultFilters.langs =
          agentTeamSubscriptionConfiguration?.authorizedLangs;
      }
    }

    return resultFilters;
  }

  function addAgentTeamFilters(
    filters: OrderEsSearchQueryDTO
  ): OrderEsSearchQueryDTO {
    const agentTeamFilters = getAgentTeamFilters();

    if (agentTeamFilters.productIds) {
      if (filters.productIds) {
        filters.productIds.push(...agentTeamFilters.productIds);
      } else {
        filters.productIds = agentTeamFilters.productIds;
      }
    }

    if (agentTeamFilters.tags) {
      if (filters.tags) {
        filters.tags.push(...agentTeamFilters.tags);
      } else {
        filters.tags = agentTeamFilters.tags;
      }
    }

    if (agentTeamFilters.langs) {
      if (filters.langs) {
        filters.langs.push(...agentTeamFilters.langs);
      } else {
        filters.langs = agentTeamFilters.langs;
      }
    }

    return filters;
  }

  function removeAgentTeamFilters(
    filters: OrderEsSearchQueryDTO
  ): OrderEsSearchQueryDTO {
    const agentTeamFilters = getAgentTeamFilters();

    if (filters.productIds && agentTeamFilters.productIds) {
      filters.productIds = filters.productIds.filter(
        (productId: string) => !agentTeamFilters.productIds?.includes(productId)
      );
    }

    if (filters.tags && agentTeamFilters.tags) {
      filters.tags = filters.tags.filter(
        (tag: string) => !agentTeamFilters.tags?.includes(tag)
      );
    }

    if (filters.langs && agentTeamFilters.langs) {
      filters.langs = filters.langs.filter(
        (lang: Lang) => !agentTeamFilters.langs?.includes(lang)
      );
    }

    return filters;
  }

  return {
    getFilters,
    setFilters,
    watchFilters,
    getResults,
    watchResults,
    getSortParameter,
    watchSortParameter,
    updateSortParameters,
    moreOrders,
    searchPageMaxRecords,
    loadOrders,
    isAgentInTeam,
    initAgentTeamSubscriptionConfiguration,
    addAgentTeamFilters,
    removeAgentTeamFilters,
    getOrders,
    setOrders,
    watchOrders,
  };
}

export default OrderFilterService();
